.testimonial-container {
    margin-top: 5rem;
    background-image: url("../../../../assets/mission-bg-img.jpg");
    min-height: 250px;
    padding: 50px 0px 50px;
    background-attachment: fixed;
    position: relative;
    background-size: cover;
}

.testimonial-text {
    /* margin: 8% 20%; */

    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
}

.border-text {
    border: 1px solid rgb(230, 30, 4);
    padding: 20px;
    width: auto;
}

.testimonial-btn button {
    margin-top: 30px;
    border-radius: 4px;
    /* background-color:; */
    padding: 10px 20px;
    border:  1px solid black;
    /* color: rgb(218, 214, 214); */
    transition: .3s;
}

.testimonial-btn button:hover {
    background-color: black;
    color: white;
}