.ourwebsite-img img {
    border-radius: 10px;
}

.ourwebsite-text {
    border-radius: 10px;

    padding: 50px 20px;
    background-color: rgb(243, 243, 243);
}

.ourwebsite-text h4 {
    text-align: start;
    color: rgb(20, 20, 20);
}

.ourwebsite-text p {
    padding: 10px 0;
    text-align: justify;
}


/* Responsive design */

@media only screen and (max-width: 647px) and (min-width: 350px) {
    .ourwebsite-img img {
        height: 300px;
        width: 100%;
    }

    .ourwebsite-text {
        margin-top: 0px;
        padding: 0px 20px;
    }

    div.marquee>a>img {
        height: 69px;
        object-fit: cover;
    }

    .latest-product-container {
        padding: 0;
    }

    .slider-btn .slick-next:before,
    .slick-prev:before {
        display: none;
    }
}