.react-international-phone-country-selector-button {
    background-color: rgb(30 66 159) !important;
}

.react-international-phone-input-container .react-international-phone-input {
    background-color: rgb(30 66 159);
    border: none;
    color: white;
    width: 100% !important;
}

.react-international-phone-input-container .react-international-phone-input:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.popup-message {
    animation: slideDown 1s ease-out forwards;
}

@keyframes slideDown {
    0% {
        top: -100px; /* Start position */
    }
    100% {
        top: 10%; /* End position - centered from the top */
    }
}
