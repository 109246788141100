.button-section-home {
    display: flex;
    justify-content: space-between
}

.button-section-home button {
    margin: 40px 20px 0 0;
    gap: 20px !important;
    padding: 10px 20px;
    background-color: white;
    color: black;
    border-radius: 5px;
}

.btn-home2 {
    background-color: black !important;
    color: white !important;
}

.button-section-home button:hover {
    background-color: black;
    color: white;
    transition: .3s;
}

.btn-home2:hover {
    background-color: white !important;
    color: #000 !important;
    transition: .3s !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    background-color: #fff;
    color: #000;
    content: "next";
    font-size: 25px !important;
    padding: 10px 15px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    background-color: #fff;
    color: #000;
    font-size: 25px !important;
    padding: 10px 15px;
}

@media only screen and (max-width: 647px) and (min-width: 350px) {

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        display: none !important;
    }

    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
        display: none !important;
    }
}